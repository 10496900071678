main.Main {
  text-align: center;
}

.Header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.Header h1 {
  margin: 0;
}
.Header h2 {
  margin: 10px auto 0;
}


.Disclaimer {
  background-color: #ebd991; /* Couleur de fond du disclaimer */
  padding: 20px;
  border-radius: 10px; /* Coins arrondis */
  text-align: left;
  margin: 20px auto; /* Centrer horizontalement */
  max-width: 800px; /* Optionnel : limitez la largeur du disclaimer */
}
@media screen and (max-width: 838px) {
  .Disclaimer {
    margin: 10px; /* Réduire l'espacement intérieur */
  }
}

.Disclaimer h2 {
  margin-top: 0; /* Supprime la marge supérieure par défaut du titre */
  color: rgb(181, 17, 17); /* Couleur du titre */
}
.Disclaimer ul {
  list-style-type: disc; /* Utiliser des points pleins comme style de puce */
  padding-left: 20px; /* Ajouter un espace entre le début de la ligne et le point */
}

.Disclaimer li {
  margin-bottom: 10px; /* Ajouter de l'espace entre chaque élément de liste */
}


.ResultsButton {
  background-color: #4CAF50; /* Couleur de fond verte */
  color: white;
  padding: 15px 30px; /* Augmentation du rembourrage */
  border: 2px solid #4CAF50; /* Bordure verte */
  border-radius: 8px; /* Augmentation du rayon de la bordure */
  margin: 40px auto; /* Centre le bouton horizontalement et ajoute une marge de 20px en haut et en bas */
  cursor: pointer;
  font-family: 'Arial', sans-serif; /* Police de caractères */
  font-weight: bold;
  font-size: 18px; /* Augmentation de la taille de la police */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Ajout de transition pour une animation fluide */
  text-transform: uppercase; /* Convertir le texte en majuscules */
}

.ResultsButton:hover {
  background-color: #45a049; /* Couleur de fond verte plus foncée au survol */
  border-color: #45a049; /* Couleur de la bordure verte plus foncée au survol */
}


.PleaseAnswer {
  color: rgb(185, 71, 71);
  font-weight: bold;
  text-align: center;
  margin-top: -25px;
}

.ProgressBar {
  position: fixed; /* Utiliser une position fixe pour maintenir la barre de progression en bas de l'écran */
  bottom: 0 !important; /* Positionner la barre de progression en bas */
  left: 0;
  height: 8px;
  background-color: #4CAF50;
  width: 0;
  /* transition: width 0.3s ease; */
  transition: .5s ease-out 0s;
}
