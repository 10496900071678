.Footer {
    background-color: #333333; /* Couleur de fond du footer */
    color: white; /* Couleur du texte */
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    bottom: 0 ;
  }
  
  .FirstContainer {
    gap: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; 
    margin-bottom: 0px; /* Espacement entre le FirstContainer et le Precog */
  }
  
  /* Média query pour changer flex-wrap lorsque la largeur est insuffisante */
  @media screen and (max-width: 768px) {
    .FirstContainer {
      flex-wrap: wrap; /* Permet aux éléments de passer sur plusieurs lignes si la largeur est insuffisante */
      gap: 10px; /* Ajuste l'espacement horizontal entre les éléments lorsqu'ils sont sur plusieurs lignes */
      align-content: center; /* Centre les éléments verticalement dans le conteneur */
    }
  }
  
  .Newsletter{
    margin-left: 20px;
  }
  
  .Newsletter input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 270px; /* Largeur du champ d'e-mail */
  }
  
  .MailSubmit {
    width: 38px;
    height: 38px;
    background-color: #333; /* Couleur de fond */
    color: white; /* Couleur du texte */
    padding: 10px; /* Rembourrage */
    border: 1px solid #999 ; /* Supprime la bordure */
    border-radius: 5px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointer */
    display: flex; /* Utiliser la flexbox */
    align-items: center; /* Aligner verticalement */
    margin: 0px; /* Marge à gauche pour séparer l'input et le bouton */
  }
  
  .MailSubmit img {
    /* Styles pour l'image à l'intérieur du bouton */
    margin-left: -4px;
    width: 25px; /* Largeur de l'image */
    height: 25px; 
  }
  
  .ContactInfo{
    padding-top: 20px;
  }
  .Precog {
    color: #999;
    margin-top: 5px; /* Espacement entre le FirstContainer et le Precog */
    margin-bottom: 10px;
  }

.FooterForm {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.errorMessage, .successMessage{
  background-color: #ecd9d9;
  color: #702d33;
  padding: 8px;
  /* border: 1px solid #f5c6cb; */
  border-radius: 4px;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 83%;
  z-index: 1;
  font-size: small;
}

.successMessage{
  background-color: #d4edda;
  color: #0d4119;
}



