.Question, .MiddleSentence {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 0 auto; /* Centre le bloc de question horizontalement */
  max-width: 800px; /* Définir une largeur maximale pour le bloc de question */
  overflow-x: auto; /* Activer le défilement horizontal si le contenu dépasse la largeur */
  overflow-y: hidden; /* Masquer le défilement vertical */
}

.MiddleSentence {
  /* font-size: larger; */
  color: #94657e;
  font-weight: 600;
}

.Question h3 {
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
}

.Choice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ChoiceButton {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Ajout du border-radius pour garder les boutons ronds */
  border: 4px solid ;
  /* background-color: #fff; */
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.Legends, .FullLegends {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 70px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: .85em;
  font-weight: 600;
}

.FullLegends {
  margin-left: -49px;
  gap: 36px;
}

.LegendContainer {
  display: flex;
  justify-content: center;
  align-items: center; /* Centre verticalement le contenu */
}

.Legend {
  font-size: 12px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}


.Choices {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 60px; 
}

@media screen and (max-width: 768px) {
  .Choices {
    gap: 10%; /* Réduire l'espace entre les boutons sur les écrans de téléphone */
  }
  .Legends {
    gap: 13%; /* Réduire l'espace entre les légendes sur les écrans de téléphone */
  }
  .FullLegends {
    gap:4%;
    margin-left: 2%;
    margin-right: 21px;
  }
}

@media screen and (min-width: 769px) {
  .Question, .MiddleSentence {
    padding: 15px 40px; /* Ajouter des marges sur les côtés pour les écrans plus larges */
  }
}

