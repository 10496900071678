.close {
    border: 2px solid #999 !important;
    border-radius: 30%;
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }

  .popup-content {
    width: fit-content;
    border: 0px !important;
    text-align: center;
    padding: 20px !important;
    margin: auto !important;
  }

  .gauge-container {
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    width: 100%; /* Utilisez 100% de la largeur de la jauge */
    height: auto; /* Ajustez la hauteur selon les besoins */
  }
  
  .gauge {
    transform: scaleX(-1);
    max-width: 60%;
    height: auto;

  }
  
  .needle {
    transition: transform 1s ease;
    position: absolute;
    left: 43%;
    top: 74%;
    max-width: 29%;
    height: auto;
    transform-origin: 22% 22%;
  }
  
  
  .comments {
    margin-top: 42px;
    margin-bottom: 0px;
    font-family: Arial, sans-serif;
    font-size: 23px;
  }

  img.BulbIcon {
    width: 41px;
    vertical-align: -12px;
  }
  
  .Hint {
    margin-bottom: 0%;
  }

  .a{
    color:darkblue;
  }
  
  